import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const wpcApi = createApi({
  reducerPath: "wpcApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://backend.workpermitconsultancy.com/",
    // baseUrl: "http://localhost:4000/",
  }),
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (clerkId) => ({
        url: `dashboard/`,
        method: "GET",
        params: { clerkId },
      }),
    }),
    getUser: builder.query({
      query: ({ clerkId, route }) => ({
        url: route || `dashboard/user`,
        method: "GET",
        params: { clerkId },
      }),
    }),

    getSingle: builder.query({
      query: (id) => `dashboard/${id}`,
    }),
    checkStatus: builder.mutation({
      query: (clerkId) => ({
        url: "/dashboard",
        method: "POST",
        params: { clerkId },
      }),
    }),
    assignToken: builder.mutation({
      query: ({ clerkId, id }) => ({
        url: `dashboard/${clerkId}/${id}`,
        method: "POST",
      }),
    }),
    tokenEmail: builder.mutation({
      query: ({ clerkId, id }) => ({
        url: `dashboard/token-email/${clerkId}/${id}`,
        method: "POST",
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `dashboard/${id}`,
        method: "DELETE",
        params: { id },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, fileStatus, body }) => ({
        url: `dashboard/${id}/${fileStatus}`,
        method: "PUT",
        body,
      }),
    }),
    basic: builder.mutation({
      query: ({ id, body }) => ({
        url: `/step-two`,
        method: "POST",
        body,
      }),
    }),
    verification: builder.mutation({
      query: ({ id, body }) => ({
        url: `/step-three`,
        method: "POST",
        body,
      }),
    }),
    additional: builder.mutation({
      query: ({ id, body }) => ({
        url: `/step-four/${id}`,
        method: "POST",
        body,
      }),
    }),
    getBasic: builder.query({
      query: ({ clerkId, _id, status }) => ({
        url: "/step-two",
        method: "GET",
        params: { clerkId },
      }),
    }),
    getVerification: builder.query({
      query: ({ clerkId, _id }) => ({
        url: "/step-three",
        method: "GET",
        params: { clerkId, _id },
      }),
    }),
    getAdditional: builder.query({
      query: ({ clerkId, _id }) => ({
        url: "/step-four",
        method: "GET",
        params: { clerkId, _id },
      }),
    }),
  }),
});

export const {
  useGetAllQuery,
  useGetSingleQuery,
  useCheckStatusMutation,
  useUpdateUserMutation,
  useGetUserQuery,
  useBasicMutation,
  useVerificationMutation,
  useAdditionalMutation,
  useAssignTokenMutation,
  useDeleteUserMutation,
  useGetBasicQuery,
  useTokenEmailMutation,
  useGetVerificationQuery,
  useGetAdditionalQuery,
} = wpcApi;
