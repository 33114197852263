import React, { useState, useRef, useEffect } from "react";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import {
  useGetAllQuery,
  useCheckStatusMutation,
  useGetUserQuery,
  useAssignTokenMutation,
  useDeleteUserMutation,
  useTokenEmailMutation,
} from "../api/api.slice";
import { useUser } from "@clerk/clerk-react";

export default function Dashboard() {
  useEffect(() => {
    document.title = "Work Permit Consultancy - Dashboard";
  }, []);

  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const { isSignedIn, user, isLoaded } = useUser();
  const [clerkId, setClerkId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const userRef = useRef(user);
  const [checkAdminStatus] = useCheckStatusMutation();
  const [checkToken] = useAssignTokenMutation();
  const [sendTokenEmail] = useTokenEmailMutation();
  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded && isSignedIn) {
        userRef.current = user;
        const clerkId = userRef.current.id;

        try {
          // Use the mutate function to send the request
          const response = await checkAdminStatus(clerkId);

          // Check if response.data exists before accessing isAdmin property
          const isAdmin = response.data && response.data.isAdmin;

          setIsAdmin(isAdmin || false);
          setClerkId(clerkId);
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      }
    };

    fetchData();
  }, []);
  const {
    data: adminData,
    // error: adminError,
    isLoading: adminLoading,
  } = useGetAllQuery(clerkId);
  const {
    data: userData,
    // error: userError,
    isLoading: userLoading,
  } = useGetUserQuery({ clerkId, route: "/dashboard/user" });
  if (adminLoading || userLoading) {
    return (
      <div className="flex items-center justify-center h-[60vh]">
        <Spinner className="h-11 w-11" color="indigo" />
      </div>
    );
  }

  let Alldata;
  if (isAdmin) {
    Alldata = adminData;
  } else {
    Alldata = userData;
  }

  const handleDelete = (item) => {
    setModalContent({
      title: "Delete User",
      message: `Please Confirm you want to delete application of ${item.basic_information?.firstName} ${item.basic_information?.lastName}`,
      action: () => confirmDelete(item._id),
    });
    setIsModalOpen(true);
  };

  const confirmDelete = async (id) => {
    try {
      await deleteUser(id);
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleButtonClick = (item) => {
    setModalContent({
      title: "Token Generation",
      message: `Please Confirm You want to generate a token for ${item.basic_information?.firstName} ${item.basic_information?.lastName}`,
      action: () => generateToken(item._id),
    });
    setIsModalOpen(true);
  };
  // const handleTokenEmail = (item) => {
  //   setModalContent({
  //     title: "Send Token To User",
  //     message: `Please confirm you want to send a verification token to ${item.basic_information?.firstName} ${item.basic_information?.lastName}`,
  //     action: () => confirmSendTokenEmail(item),
  //   });
  //   setIsModalOpen(true);
  // };

  // const confirmSendTokenEmail = async (item) => {
  //   try {
  //     await sendTokenEmail({ clerkId, id: item._id });
  //     setIsModalOpen(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error sending token email:", error);
  //   }
  // };
  const handleTokenEmail = (item) => {
    setModalContent({
      title: "Send Token To User",
      message: `Please confirm you want to send verification token to ${item.basic_information?.firstName} ${item.basic_information?.lastName}`,
      action: () => confirmSendTokenEmail(item),
      isLoading: false,
    });
    setIsModalOpen(true);
  };

  const confirmSendTokenEmail = async (item) => {
    try {
      setModalContent((prevState) => ({
        ...prevState,
        isLoading: true, // Show loading state
        message: `Sending verification token to ${item.basic_information?.firstName} ${item.basic_information?.lastName}...`,
      }));

      await sendTokenEmail({ clerkId, id: item._id });
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error sending token email:", error);
      // Handle error feedback if necessary
    }
  };

  const generateToken = async (id) => {
    await checkToken({ clerkId, id });
    setIsModalOpen(false);
    window.location.reload();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handlePreview = (item) => {
    navigate("/apply-now", { state: { props: item } });
  };

  const handleUpdate = (item) => {
    navigate("/apply-now", { state: { update: item } });
  };
  const handleAddNew = () => {
    navigate("/apply-now");
  };

  return (
    <div>
      {isAdmin ? (
        <>
          <header className="bg-myBlue text-white text-center py-8">
            <h1 className="text-2xl font-bold font-playfairDisplay capitalize">
              Welcome Admin {user.firstName}
            </h1>
          </header>
          <main className="container mx-auto mt-8 p-4 min-h-[50vh]">
            <h2 className="my-2 font-Roboto font-semibold text-2xl text-myBlue">
              Applications
            </h2>
            <section className=" overflow-auto rounded-[12px]">
              {Alldata?.data && Alldata?.count > 0 ? (
                <table className="min-w-full bg-white border border-gray-300 ">
                  <thead className="text-center bg-myBlue text-white ">
                    <tr>
                      <th className="py-2 px-4 border-b">First Name</th>
                      <th className="py-2 px-4 border-b">Last Name</th>
                      <th className="py-2 px-4 border-b">Email</th>
                      <th className="py-2 px-4 border-b">Token</th>
                      <th className="py-2 px-4 border-b">Send Token</th>
                      <th className="py-2 px-4 border-b">Status</th>
                      <th className="py-2 px-4 border-b">View</th>
                      <th className="py-2 px-4 border-b">Delete</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {Alldata.data.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-gray-100" : ""}
                      >
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.basic_information.firstName}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.basic_information.lastName}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.basic_information.email}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          {!item.verificationToken ? (
                            <button
                              onClick={() => handleButtonClick(item)}
                              className="bg-myBlue hover:bg-transparent text-white hover:outline hover:outline-[2px] hover:outline-myBlue hover:text-myBlue px-2 py-1 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            >
                              Generate
                            </button>
                          ) : (
                            <p>{item.verificationToken}</p>
                          )}
                        </td>
                        <td>
                          {!item.verificationToken ? (
                            <button
                              onClick={() => handleTokenEmail(item)}
                              disabled={true}
                              className="bg-[#909090]  text-white text-sm px-[5px] rounded "
                            >
                              Send
                            </button>
                          ) : item.sentEmail === "not-sent" ? (
                            <button
                              onClick={() => handleTokenEmail(item)}
                              className="bg-myBlue text-sm px-[5px]  hover:bg-transparent text-white hover:outline hover:outline-[2px] hover:outline-myBlue hover:text-myBlue  rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            >
                              Send
                            </button>
                          ) : (
                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                              {item.sentEmail}
                            </span>
                          )}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.status}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          <button
                            onClick={() => handlePreview(item)}
                            className="bg-transparent hover:text-blue-500 text-myBlue font-semibold  hover:border-transparent rounded"
                          >
                            <IoEye size={25} />
                          </button>
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          <button
                            onClick={() => handleDelete(item)}
                            className="bg-transparent hover:text-red-500 text-myBlue font-semibold  hover:border-transparent rounded"
                          >
                            <RiDeleteBin6Fill size={24} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No data available</p>
              )}
            </section>
          </main>
        </>
      ) : (
        <>
          <header className="bg-myBlue text-white text-center py-8">
            <h1 className="text-2xl font-bold font-playfairDisplay capitalize">
              Welcome {user.fullName}
            </h1>
          </header>
          <main className="container mx-auto mt-8 p-4 min-h-[50vh] ">
            <div className="flex gap-4 items-center content-center">
              <h2 className="my-2 font-Roboto font-semibold text-2xl text-myBlue">
                Your Applications
              </h2>
              <button onClick={handleAddNew}>
                <IoMdAddCircle size={25} color={"#094b72"} />
              </button>
            </div>

            <section className=" overflow-auto rounded-[12px]">
              {Alldata?.data && Alldata?.count > 0 ? (
                <table className="min-w-full bg-white border border-gray-300 ">
                  <thead className="text-center bg-myBlue text-white ">
                    <tr>
                      <th className="py-2 px-4 border-b">First Name</th>
                      <th className="py-2 px-4 border-b">Last Name</th>
                      <th className="py-2 px-4 border-b">Email</th>
                      <th className="py-2 px-4 border-b">View</th>
                      <th className="py-2 px-4 border-b">Edit</th>
                      <th className="py-2 px-4 border-b">Delete</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {Alldata.data.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-gray-100" : ""}
                      >
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.basic_information.firstName}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.basic_information.lastName}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          {item.basic_information.email}
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          <button
                            onClick={() => handlePreview(item)}
                            className="bg-transparent hover:text-blue-500 text-myBlue font-semibold  hover:border-transparent rounded"
                          >
                            <IoEye size={25} />
                          </button>
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          <button
                            onClick={() => handleUpdate(item)}
                            disabled={item.status === "updated"}
                            className={
                              item.status === "updated"
                                ? "text-[#808080]"
                                : "bg-transparent hover:text-blue-500 text-myBlue font-semibold hover:border-transparent rounded"
                            }
                          >
                            <MdModeEdit size={25} />
                          </button>
                        </td>
                        <td key={item.id} className="py-2 px-4 border-b">
                          <button
                            onClick={() => handleDelete(item)}
                            disabled={
                              item.status === "updated" ||
                              item.status === "completed"
                            }
                            className={
                              item.status === "updated" ||
                              item.status === "completed"
                                ? "text-[#808080]"
                                : "bg-transparent hover:text-red-500 text-myBlue font-semibold  hover:border-transparent rounded"
                            }
                          >
                            <RiDeleteBin6Fill size={24} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Please submit an application to view here </p>
              )}
            </section>
          </main>
        </>
      )}
      {isModalOpen && (
        <div
          id="popup-modal"
          className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-gray-700 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                onClick={closeModal}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
              >
                {/* Close icon */}
              </button>
              <div className="p-4 md:p-5 text-center">
                <h2 className="mb-5 text-lg font-bold font-Roboto text-[#094b72] underline">
                  {modalContent.title}
                </h2>
                <p className="mb-5 px-2">{modalContent.message}</p>
                {!modalContent.isLoading && (
                  <div>
                    <button
                      onClick={modalContent.action}
                      className="bg-[#094b72] text-white px-5 py-2 rounded-lg text-sm"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={closeModal}
                      className="ml-3 px-5 py-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
